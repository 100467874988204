import axios from 'axios'
// import config from '@/config'
import { store } from '../store/index'
import router from '../router'
import { getLangName } from '@/utils/lang'
import { isDev } from '@/utils/utils'
import { isWechatMiniProgram } from '@/utils/utils'

import { i18n } from '@/i18n'
import { getLangCode } from '../utils/lang'
import { setupDefaultQueryString } from '../utils/utils'

const ClientOAuth2 = require('client-oauth2')

const getRedirectUri = function () {
  const loc = window.location

  const result = loc.protocol + '//' + loc.host + '/verifycode'

  console.log('@13', result)

  return result
}

const getSsoAuthClient = function (state) {
  if (!state) {
    state = '1234567890123123'
  }
  console.log('state---- ', state)
  const lang = getLangName(router.app.$i18n.locale)
  let authUrl = `${process.env.VUE_APP_AUTH_URL}/oauth2/auth?locale=${lang}&from=${process.env.VUE_APP_SIGNIN_FRONTEND_NAME}`

  authUrl = setupDefaultQueryString(router.currentRoute, authUrl)

  return new ClientOAuth2({
    clientId: 'hktb-frontend-client',
    // clientSecret: '5185119784',
    clientSecret: '',
    accessTokenUri: `${process.env.VUE_APP_AUTH_URL}/oauth2/token`,
    authorizationUri: authUrl,
    redirectUri: getRedirectUri(),
    scopes: ['offline'],
    state: state
  })
}

const doLogin = async function () {
  store.commit('auth/clearUser')
  localStorage.setItem('refreshToken', '')
  localStorage.setItem('accessToken', '')
  store.commit('local/clear')

  loginSSO()
  return
  // will do final logout at the
}

const doLogout = async function (logoutUrl, revokeAll) {
  console.log('@580', process.env.VUE_APP_SSO_URL, router.app.$i18n.locale)
  const lang = getLangName(router.app.$i18n.locale)
  const langCode = getLangCode(router.app.$i18n.locale)

  let ssoLogoutUrl = `${process.env.VUE_APP_SSO_URL}/auth/logout`
  ssoLogoutUrl = setupDefaultQueryString(router.currentRoute, ssoLogoutUrl)

  let redirectUrl = `${window.location.origin}/${langCode}/home`
  redirectUrl = encodeURIComponent(setupDefaultQueryString(router.currentRoute, redirectUrl))

  const extraParameter = process.env.VUE_APP_SIGNIN_FRONTEND_NAME ? '&from=' + process.env.VUE_APP_SIGNIN_FRONTEND_NAME : ''
  let authLogoutURL = logoutUrl || `${process.env.VUE_APP_AUTH_URL}/auth/signout-redirect?redirect_to=${redirectUrl}&lang=${lang}${extraParameter}`

  authLogoutURL = setupDefaultQueryString(router.currentRoute, authLogoutURL)

  console.log('@591', ssoLogoutUrl, authLogoutURL, router.currentRoute)
  // const prevWithCredentials = axios.defaults.withCredentials

  // axios.defaults.withCredentials = true
  try {
    const response = await axios.post(ssoLogoutUrl, {
      revoke_all: revokeAll
    }, {
      withCredentials: true
    })

    console.log('@530 logout result for sso', response)
  } catch (error) {
    console.log('@586 error while logout sso', error)
  }

  store.commit('auth/clearUser')
  localStorage.setItem('refreshToken', '')
  localStorage.setItem('accessToken', '')
  store.commit('local/clear')
  // will do final logout at the end
  // router.push({ name: 'home' })

  // axios.defaults.withCredentials = prevWithCredentials

  // pushRouterWithQuery(this.$router, { 'name': 'home' })
  // clean up all workbox user
  // https://stackoverflow.com/questions/54376355/clear-workbox-cache-of-all-content
  if (caches) {
    caches.keys().then(cacheNames => {
      console.log('@448, removing cache...', cacheNames)
      cacheNames.forEach(cacheName => {
        caches.delete(cacheName)
      })
    }).catch(error => {
      console.log('@448b, failed to remove cache', error)
    })
  }

  setTimeout(function () { // per request by wilfred, current is not too stable, give some time for cache delete
    window.location.href = authLogoutURL
  }, 100) // change from 1000 to 100
  return
}

const getRefreshSsoTokenPromise = function () {
  const refreshToken = localStorage.getItem('refreshToken')
  const data = `grant_type=refresh_token&refresh_token=${refreshToken}&redirect_uri=${getRedirectUri()}`
  // const refreshAuthHeaderString = btoa('hktb-frontend-client:5185119784')

  return axios({
    baseURL: process.env.VUE_APP_AUTH_URL,
    url: '/oauth2/token',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'

    },
    data: data
    // withCredentials: true
  })
}

const getCampaignDetail = function (campaignId) {
  axios.get(`/campaigns/${campaignId}`).then(response => {
    console.log('@8762, campaign response', response)
    const detail = response.data
    if (isDev() && detail && detail.name) {
      detail.name = detail.name.replace('<DATETIME>', (new Date().getTime()))
    }
  })
}

const getCampaignlimit = function (store) {
  axios.get(`/customers/check_campaign_limits`)
    .then(response => {
      console.log('@8146, limits response', response)
      store.commit('campaigns/setCampaignLimits', response.data)
    })
    .catch(error => {
      console.log('error----', error)
    })
}

const asyncGetCampaignDetail = function (campaignId) {
  return new Promise((resolve, reject) => {
    axios.get(`/campaigns/${campaignId}`)
      .then(response => {
        console.log('@8762, campaign response', response)
        const detail = response.data
        if (isDev() && detail && detail.name) {
          detail.name = detail.name.replace('<DATETIME>', (new Date().getTime()))
        }

        console.log('@108', detail)

        store.commit('campaigns/updateCampaignDetail', { id: campaignId, detail })
        resolve(detail)
      })
      .catch(reject)
  })
}

const shuffle = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

const fetchCampaigns = async (params, sorting = null, cancelToken = null) => {
  sorting = sorting || 'featured'
  const queryString = new URLSearchParams(params).toString()
  console.log('queryString', queryString)

  let results = []
  let count = 0
  const requestConfig = { timeout: 60000 }
  if (cancelToken) {
    requestConfig.cancelToken = cancelToken
  }
  try {
    const url = `/v3/campaigns?${queryString}`;

    ({ data: { count, results }} = await axios.get(url, requestConfig))
  } catch (error) {
    console.log('error', error)
  }

  const STOCK_CAMPAIGN_TYPES = ['COUPON_CAMPAIGN', 'CASH_VOUCHER_CAMPAIGN_FOR_GOODIE_BAG', 'GIFT_CAMPAIGN_FOR_GOODIE_BAG']
  if (sorting === 'featured' || sorting === '-is_starred') {
    const inStockItemList = results.filter(item => !STOCK_CAMPAIGN_TYPES.includes(item.type) || (STOCK_CAMPAIGN_TYPES.includes(item.type) && item.coupon_campaign_type_coupon_stock > 0))
    const soldOutItemList = results.filter(item => STOCK_CAMPAIGN_TYPES.includes(item.type) && item.coupon_campaign_type_coupon_stock <= 0)

    let sortingData
    let noneSortingData

    if (sorting === 'featured') {
      sortingData = shuffle(inStockItemList.filter(item => item.is_featured))
      noneSortingData = shuffle(inStockItemList.filter(item => !item.is_featured))
    } else {
      sortingData = shuffle(inStockItemList.filter(item => item.is_starred))
      noneSortingData = shuffle(inStockItemList.filter(item => !item.is_starred))
    }
    results = [...sortingData, ...noneSortingData, ...soldOutItemList]
  }

  return { count, results }
}

/*
.then(response => {
    store.commit('auth/setToken', {
      refreshToken: response.data.refresh_token,
      accessToken: response.data.access_token
    })
  }).catch(error => {
    console.log(error)
  })
*/

let refreshTokenPromise

function isNumeric (str) {
  if (typeof str !== 'string') return false // we only process strings!
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export default function axiosSetUp () {
  // const { API_URL } = env
  axios.defaults.baseURL = process.env.VUE_APP_API_URL
  // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
  axios.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem('accessToken')
      const { params } = config

      let headers = { ...config.headers }

      console.log('@250', config.url)

      const profile = store.getters['auth/userProfile']
      // console.log('@226', profile)
      const isPreviewTester = profile && profile.is_preview_tester

      if (accessToken) {
        // some special url no need to insert jwt towkn

        let escapeTokenInsert = false
        if (config.url.includes('&goodie_bag=')) {
          // never esacape if the url contains this
          console.log('@256e, no escape for goodiebag url', config.url)
        } else if (config.url.startsWith('/campaigns/') && window.location.href.includes('recordId=')) {
          // never escape if the page is for goodiebag record
          console.log('@256d, no escape for goodiebag campaign')
        } else if (isPreviewTester || window.location.href.includes('tester_preview')) {
          // never escape if is test uesr
          console.log('@256c, no escape for test user')
        } else if (config.url.startsWith('/v2/attribute_tags') ||
          config.url.startsWith('/v3/campaigns') ||
          config.url.startsWith('/campaigns/')
        ) {
          console.log('@256b, skip url handling for', config.url)

          escapeTokenInsert = true
        }

        // escapeTokenInsert = false // disable this check for now

        if (config.url !== '/oauth2/token' && !escapeTokenInsert) {
          headers = { ...config.headers, Authorization: `Bearer ${accessToken}` }
        }
      }

      // console.log('64----- router: ', router.app.$i18n.locale)

      const lang = getLangName(router.app.$i18n.locale)

      const extraParams = { 'lang': lang }
      // some special handling for tester preview pages
      if (config.url &&
          (config.url.includes('/campaigns') ||
          config.url.includes('/goodie_bag_participation_records') ||
          config.url.includes('/goodie_bags'))
      ) {
        // console.log('@226', profile)
        if (isPreviewTester) {
          extraParams['tester_preview'] = 'true'
        }
      }
      return { ...config, headers, params: { ...params, ...extraParams }}
    }
  )

  axios.interceptors.response.use((response) => response, async (error) => {
    console.log('@150', JSON.stringify(error))

    let status = error && error.response && error.response.status ? error.response.status : 500
    if (!status && error.message) {
      const parts = error.message.split(' ')
      if (parts.length && isNumeric(parts[parts.length - 1])) {
        status = parseInt(parts[parts.length - 1])
      }
    }

    console.log('@166', status)

    if (!status) { return Promise.reject(error) }

    // refresh token
    console.log('@279---- error', error.config, error.response, error.request)
    const originalRequest = error.config

    if ([400].indexOf(status) > -1 &&
        (originalRequest.url.includes('oauth2/token'))
    ) {
      console.log('@69---- refresh token error')

      await doLogin()

      return
    }

    if ([400, 401, 500].indexOf(status) > -1 &&
        (originalRequest.url.includes('oauth2/token'))
    ) {
      console.log('@69---- refresh token error')

      await doLogout(null, false)

      return
    }

    if ([401].indexOf(status) > -1 &&
    (originalRequest.url.includes('/logout'))) {
      // problem when logout
      return Promise.reject(error)
    }

    if ([401, 403].indexOf(status) > -1 &&
    (originalRequest.url.includes('/test_usage_record'))) {
      // problem when test usage record
      return Promise.reject(error)
    }

    console.log('@93---- response---', error.response)

    if (status === 403 || status === 401) {
      console.log('95------ refresh token promise', refreshTokenPromise)
      if (!refreshTokenPromise) {
        refreshTokenPromise = getRefreshSsoTokenPromise().then(response => {
          refreshTokenPromise = null
          console.log('get refresh ------- access token', response)
          localStorage.setItem('refreshToken', response.data.refresh_token)
          localStorage.setItem('accessToken', response.data.access_token)
          return response.data.access_token
        }).catch(error => {
          console.log('refresh token error: ', error.response)
          return Promise.reject(error)
        })
      }
      console.log('95------ refresh token promise', refreshTokenPromise)

      return refreshTokenPromise.then(token => {
        console.log('get refresh ------- access token', token)
        if (token) {
          error.config.headers['Authorization'] = `Bearer ${token}`
          return axios(error.config)
        }
      })
    // } else if (error.response.status >= 500 || error.response.status >= 400) {
    } else if (status === 429 || status >= 500) {
      console.log('@129, server error', i18n)
      const message = status === 429 ? i18n.t('generalServerBusyError') : i18n.t('generalServerError')
      /*
      const { data } = error.response
      if (data && data.detail) {
        message = data.detail
      }
      */

      console.log('@136', message)
      router.app.$message.closeAll()
      let offset = 77
      if (window.$('.is-vue-mobile').length) {
        offset = 73
      }
      if (window.$('.nav').height() === 0) {
        offset = 10
      }

      router.app.$message({
        dangerouslyUseHTMLString: true,
        message: `<strong><img src='/img/error_icon.png' width='18px' height='18px'>${message}</strong>`,
        customClass: 'server-error',
        type: '',
        duration: 5000,
        offset: offset
      })
    }

    return Promise.reject(error)
  })
}

const getSsoAuthRedirectURL = function (url) {
  if (!url) {
    url = `${window.location.pathname}${window.location.search}`
  }

  if (isWechatMiniProgram()) {
    let toJion = ''
    if (!url.includes('aaid')) {
      toJion += `aaid=${store.getters['app/getWechatAdobeAid']}&platform=wechat`
    }
    if (toJion) {
      if (url.includes('?')) {
        url += `&${toJion}`
      } else {
        url += `?${toJion}`
      }
    }
  }

  const state = {
    'url': url,
    'info': {
    }
  }

  const urlParams = new URLSearchParams(window.location.search)
  const wf = urlParams.get('wf')

  console.log('@448a', router.currentRoute, window.location.href, wf)
  if (wf) {
    console.log('@448, start to set up wf', wf)
    state.info.wf = wf
  }

  const ssoAuth = getSsoAuthClient(JSON.stringify(state))
  const redirectUrl = ssoAuth.code.getUri()

  return redirectUrl
}

const loginSSO = function (url, loginClicked) {
  const redirectUrl = getSsoAuthRedirectURL(url)
  if (isWechatMiniProgram()) {
    const query = { redirectUrl }
    if (!loginClicked) {
      query.hgb = 1 // if not click login, then hide go home button
    }
    console.log('@358', query)
    router.push({ name: 'wechat-login', query, params: { lang: router.app.$i18n.locale }})
  } else {
    window.open(redirectUrl, '_self')
  }
}

const redirectToH5Wechat = function (redirectUrl) {
  // const redirectUrl = this.getSsoAuthRedirectURL()
  const finalUrl = process.env.VUE_APP_WECHAT_H5_URL + '&redirect_url=' + encodeURIComponent(redirectUrl)
  console.log('@411', finalUrl)
  window.open(finalUrl, '_self')
}

export {
  getSsoAuthClient,
  getRefreshSsoTokenPromise,
  getCampaignDetail,
  asyncGetCampaignDetail,
  doLogout,
  loginSSO,
  redirectToH5Wechat,
  fetchCampaigns,
  getCampaignlimit,
  doLogin
}
