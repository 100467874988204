import { isUnavailable, isAvailable, isRedeemedOrExpiredInThreeMonth, isRedeemed, isExpired } from '@/utils/coupon'
import axios from 'axios'
// import moment from 'moment'

export const coupons = {
  namespaced: true,
  state: {
    coupons: [],
    couponDetailList: [],
    query: 'All status',
    couponType: 'ALL',
    needInitQuery: true
  },
  actions: {
    refreshCoupons: async ({ commit, rootState }) => {
      const ssoUid = rootState.auth.ssoUid
      const url = `/customers/${ssoUid}/coupons?sort=-date_of_acquisition&page[size]=500&page[number]=1`
      const response = await axios.get(url)
      const coupons = response.data || []
      commit('initCoupons', coupons)
    }
  },
  mutations: {
    initCoupons (state, coupons) {
      state.coupons = coupons
    },
    setNeedInitQuery (state, value) {
      state.needInitQuery = value
    },
    setQuery (state, query) {
      state.query = query
    },
    setFilterCouponType (state, couponType) {
      console.log('couopn type: ', couponType)
      state.couponType = couponType
    },
    updateCoupon (state, coupon) {
      console.log('update coupon---', coupon)
      state.couponDetailList = [
        ...state.couponDetailList.filter(item => item.id !== coupon.id),
        coupon
      ]

      state.coupons = [
        ...state.coupons.filter(item => item.id !== coupon.id),
        coupon
      ]
    }
  },
  getters: {
    couponType: (state) => {
      return state.couponType
    },
    needInitQuery: (state) => {
      return state.needInitQuery
    },
    couponList: (state) => (query, couponTypes = null) => {
      // console.log('52---- state.coupons', state.coupons, couponTypes)

      // filter out wrong types
      const coupons = state.coupons.filter(item => {
        /*
        if (!item.campaign || !item.campaign) {
          return false
        }
        */
        // const campaignType = item.campaign ? item.campaign.type : 'COUPON_CAMPAIGN'

        /* if (couponTypes) {
          console.log('@67', couponTypes, campaignType, item.template.display_in)
          if (campaignType === 'GIFT_CAMPAIGN_FOR_GOODIE_BAG') {
            if (!couponTypes.includes('COUPON_SECTION')) {
              return false
            }
          } else if (campaignType === 'COUPON_CAMPAIGN' || campaignType === 'FORTUNE_BAG_CAMPAIGN') {
            if (!couponTypes.includes(item.template.display_in)) {
              return false
            }
          } else {
            if (!couponTypes.includes('VOUCHER_SECTION') &&
            campaignType === 'CASH_VOUCHER_CAMPAIGN_FOR_GOODIE_BAG') {
              return false
            }
            if (!couponTypes.includes('LIMITED_OFFER_SECTION') &&
            campaignType === 'GIFT_CAMPAIGN_FOR_GOODIE_BAG') {
              return false
            }

            // if (!couponTypes.includes('FORTUNE_BAG_SECTION') &&
            // campaignType === 'FORTUNE_BAG_CAMPAIGN') {
            //  return false
            // }

            if (!couponTypes.includes('GENERAL_MESSAGE_SECTION') &&
            campaignType === 'GENERAL_MESSAGE_CAMPAIGN') {
              return false
            }
          }
        }*/

        if (couponTypes) {
          if (!couponTypes.includes(item.template.display_in)) {
            return false
          }
        }

        if (query === 'Redeemed and Expired') {
          return isRedeemedOrExpiredInThreeMonth(item)
        } else if (query === 'Available and Unavailable') {
          return isUnavailable(item) || isAvailable(item)
        } else {
          return isAvailable(item) || isUnavailable(item) || isRedeemedOrExpiredInThreeMonth(item)
        }
      })
      return coupons.sort((a, b) => {
        if (!isRedeemedOrExpiredInThreeMonth(a) && isRedeemedOrExpiredInThreeMonth(b)) {
          return -1
        }
        if (isRedeemedOrExpiredInThreeMonth(a) && !isRedeemedOrExpiredInThreeMonth(b)) {
          return 1
        }
        if (!isRedeemed(a) && isRedeemed(b)) {
          return 1
        }
        if (isRedeemed(a) && !isRedeemed(b)) {
          return -1
        }
        if (isRedeemed(a) && isRedeemed(b) && a.used_at !== b.used_at) {
          return Date.parse(b.used_at) - Date.parse(a.used_at)
        }
        if (isExpired(a) && isExpired(b) && a.expiry_date !== b.expiry_date) {
          return Date.parse(b.expiry_date) - Date.parse(a.expiry_date)
        }
        if (a.date_of_acquisition !== b.date_of_acquisition) {
          return new Date(b.date_of_acquisition) - new Date(a.date_of_acquisition)
        }
        /*
        if (a.date_of_acquisition !== b.date_of_acquisition) {
          const aRecently = moment(new Date()).diff(a.date_of_acquisition, 'hours') <= 24
          const bRecently = moment(new Date()).diff(b.date_of_acquisition, 'hours') <= 24
          if (aRecently && bRecently) {
            return new Date(b.date_of_acquisition) - new Date(a.date_of_acquisition)
          } else if (!aRecently && bRecently) {
            return 1
          } else if (aRecently && !bRecently) {
            return -1
          }
        }
        if (!isAvailable(a) && isAvailable(b)) {
          return 1
        }
        if (isAvailable(a) && !isAvailable(b)) {
          return -1
        }
        // following logic are both a & b not recently
        if (!a.expiry_date && b.expiry_date) {
          return 1
        }
        if (a.expiry_date && !b.expiry_date) {
          return -1
        }
        if (a.expiry_date && b.expiry_date && a.expiry_date !== b.expiry_date) {
          return Date.parse(a.expiry_date) - Date.parse(b.expiry_date)
        }
        */

        // console.log('@96,', a)
        if (a.template.name && b.template.name) {
          return a.template.name.toLowerCase() > b.template.name.toLowerCase() ? 1 : -1
        }

        // template name not exist, should not happen

        return 0
      })
    },
    couponDetail: (state) => (id) => {
      const result = state.couponDetailList.find(coupon => parseInt(coupon.id) === parseInt(id))

      if (result) { return result }

      return state.coupons.find(coupon => parseInt(coupon.id) === parseInt(id))
    },
    query: (state) => {
      return state.query
    }
  }
}

export default coupons
