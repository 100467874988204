<template>
  <iframe id="external_page_iframe" :src="sourceUrl" frameBorder="0" />
</template>

<script>
// import config from '@/config'

// import env from '@env'
import { getLangName } from '@/utils/lang'
import { isWechatMiniProgram } from '@/utils/utils'
export default ({
  data: function () {
    return {
      sourceUrl2: ''
    }
  },
  computed: {
    sourceUrl () {
      const { type: pageType } = this.$route.query
      const { lang } = this.$route.params
      const langName = getLangName(lang).toLowerCase()
      let url = process.env.VUE_APP_POLICY_URL + `/about/faq/${langName}/`

      if (pageType === 'tc') {
        url = process.env.VUE_APP_POLICY_URL + `/about/terms-conditions/${langName}/`
      } else if (pageType === 'privacy') {
        url = process.env.VUE_APP_POLICY_URL + `/about/privacy-policy/${langName}/`
      } else if (pageType === 'cookie') {
        url = process.env.VUE_APP_POLICY_URL + `/about/cookie-policy/${langName}/`
      }

      if (isWechatMiniProgram()) {
        url = url.replace(`/${langName}/`, `-no-link/${langName}/`)
      }
      /*
      if (pageType === 'privacy') {
        url = '/en/home'
      } else {
        url = '/en/campaign-detail?campaignId=564'
      }
*/
      console.log('@41', url)
      return url
    }
  },
  mounted () {
    var originalHeight = null
    var showManageCookieButton = this.$store.getters['app/getNeedShowCookieButton']
    if (this.$route.name === 'policy' && this.$route.query.type === 'cookie') {
      this.$store.commit('app/setNeedShowCookieButton', true)
    }

    // disabled now due to cross origin limitation
    window.$('#external_page_iframe').on('load', function () {
      if (originalHeight === null) {
        originalHeight = window.$(this).height()
      }

      /*
      var displayHeight = originalHeight
      if (showManageCookieButton) {
        displayHeight -= 90
      }
      */
      var displayHeight = originalHeight

      if (window.$('#ot-sdk-btn:visible').length) {
        displayHeight = displayHeight - 90
      }

      console.log('@35, iframe loaded', showManageCookieButton, originalHeight, displayHeight)
      window.$(this).height(displayHeight + 'px')
      // if same domain, get adjust to height of iframe
      window.$(this).height(window.$(this).get(0).contentWindow.document.documentElement.scrollHeight + 'px')

      // window.$('this').setAttribute('frameBorder', '0')
    })
  },
  async beforeRouteUpdate (to, from, next) {
    if (to.name === 'policy' && to.query.type === 'cookie') {
      console.log('@100, unset sticky footer', to)
      this.$store.commit('app/setNeedShowCookieButton', true)
    } else {
      this.$store.commit('app/setNeedShowCookieButton', false)
    }
    next()
  },
  beforeRouteLeave (to, from, next) {
    // ...
    this.$store.commit('app/setNeedShowCookieButton', false)
    next()
  }

})
</script>
<style scoped>

iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

</style>

<style lang="scss">
.document-policy .main-container {
  background-color: white
}
</style>

