<template>
  <div
    v-if="isShowGoodieBagImage"
    class="goodie-bag-image-container"
  >
    <div
      v-if="goodieBagImageUrl"
      class="goodie-bag-image"
    >
      <img :src="goodieBagImageUrl">
    </div>
    <div
      v-else
      class="axios-loading"
    />
  </div>
</template>

<script>

export default {
  computed: {
    goodieBagImageUrl () {
      const goodieBagImageUrl = this.$store.getters['app/getGoodieBagImageUrl']
      return goodieBagImageUrl || null
    },
    isShowGoodieBagImage () {
      return this.$store.getters['app/isShowGoodieBagImage']
    }
  }
}
</script>

<style lang="scss" scoped>

.is-vue-desktop {
  .goodie-bag-image-container {
    .goodie-bag-image {
      padding-bottom: 500px;
    }
  }

  .axios-loading {
    padding-top: calc(641/1923 * 100%);
  }
}

.axios-loading {
  background: linear-gradient(90deg, #D8D8D8 3.12%, #D8D8D8 47.77%, #EFEFEF 68.7%, #D8D8D8 88.6%, #D8D8D8 100%);
  padding-top: calc(3/4 * 100%);
}

.goodie-bag-image-container {
  flex: 1;

  .goodie-bag-image {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;

    img {
      object-fit: cover;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}

</style>

