<template>
  <div>
    <div class="menu-row">
      <div class="menu-container">
        <div class="my-wallet-title">{{ $t('headerMyWalletLow') }}</div>
        <div>
          <div :class="['menu-item', {'is-active': isAllActive}]" @click="changeTab('my-wallet-all')">
            <div class="menu-text menu-my-coupons">
              {{ $t('goodiebagAll') }}
              <span style="display:none">({{ allLength }})</span>
            </div>
          </div>
          <div data-aid="wallet_cashvouchers" :class="['menu-item', {'is-active': isVoucherActive}]" @click="changeTab('my-voucher')">
            <div class="menu-text menu-my-vouchers">
              {{ $t('goodiebagCashVouchers') }}
              <span style="display:none">({{ voucherLength }})</span>
            </div>
          </div>
          <!--
          <div data-aid="wallet_limitedoffers" :class="['menu-item', {'is-active': isLimitedActive}]" @click="selectGifts()">
            <div class="menu-text menu-my-vouchers">{{ $t('goodiebagLimitedOffers') }} ({{ limitedLength }})</div>
          </div>
          -->
          <div data-aid="wallet_coupons" :class="['menu-item', {'is-active': isCouponActive}]" @click="changeTab('my-coupons')">
            <div class="menu-text menu-my-vouchers">
              {{ $t('myWalletCoupons') }}
              <span style="display:none">({{ couponsLength }})</span>
            </div>
          </div>
          <div data-aid="wallet_tickets" :class="['menu-item', {'is-active': isTicketsActive}]" @click="changeTab('my-tickets')">
            <div class="menu-text menu-my-vouchers">
              {{ $t('myWalletTickets') }}
              <span style="display:none">({{ ticketsLength }})</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="customerOwnedStarCampaignCouponQuantity && goodiePerHeadOwnStarredLimit" class="starred-row">
      <div class="starred-label"><div>{{ $t('campaignSTARRED') }}</div></div>
      <div class="line" v-html="$t('myWalletStarredAlert', {'count':customerOwnedStarCampaignCouponQuantity, 'limit': goodiePerHeadOwnStarredLimit})" />
    </div>
    <div class="select-row">
      <el-select v-model="statusFilter" class="menu-select">
        <el-option v-for="item in statusFilterList" :key="item.value" :value="item.value" :label="item.label" />
      </el-select>

    </div>

    <div v-if="(itemList && itemList.length) || $store.getters['app/getIsCouponItemLoading']" class="coupon-container">
      <coupon-loading-item
        v-for="index in 8"
        v-show="$store.getters['app/getIsCouponItemLoading']"
        :key="index + '-loading'"
      />
      <coupon-item
        v-for="(item, index) in itemList"
        :key="index"
        data-aid="wallet_list_coupon_click"
        :data-cou-id="item.id"
        :item="item"
        :is-campaign="false"
      />
    </div>

    <div v-else class="discover-container">
      <img
        v-if="this.$store.getters['app/isMobile']"
        width="92px"
        srcset="/img/blankWallet_mobile.1x.png, /img/blankWallet_mobile.2x.png 2x, /img/blankWallet_mobile.3x.png 3x"
        src="/img/blankWallet_mobile.2x.png"
      >
      <img
        v-else
        width="125px"
        srcset="/img/blankWallet.1x.png, /img/blankWallet.2x.png 2x, /img/blankWallet.3x.png 3x"
        src="/img/blankWallet.2x.png"
      >
      <div v-if="isVoucherActive" class="description-1">{{ $t('goodiebagVoucharNothingHere') }}</div>
      <div v-else-if="isCouponActive" class="description-1">{{ $t('goodiebagCouponsNothingHere') }}</div>
      <div v-else-if="isTicketsActive" class="description-1">{{ $t('goodiebagTicketsNothingHere') }}</div>
      <div v-else class="description-1">{{ $t('goodiebagNothingHere') }}</div>
      <div v-if="!isTicketsActive">
        <a v-if="isWechatMiniProgram()" class="description-2" @click="directHome()">{{ $t('goodiebagBrowseAroundCenter').replace(' ', '\xa0') }}</a>
        <a v-else class="description-2" target="_blank" :href="discoverHref">{{ $t('goodiebagBrowseAroundCenter').replace(' ', '\xa0') }}</a>
      </div>
    </div>

    <el-dialog
      :visible.sync="isShowFortuneBagCongratulationsDialog"
      :width="$store.getters['app/isMobile']?'316px':'600px'"
      class="fortune-bag-acquire-success-dialog"
      custom-class="acquire-success-dialog-body"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="acquire-success-img"><img src="/img/congratulations.png"></div>
      <div class="scroll-area">
        <div class="acquire-success-title">{{ $t('acquiredFortuneBagCouponsSuccessful') }}</div>
        <div class="coupon-found-text">{{ $t('tipShowFortuneBagCoupon') }}</div>
        <div
          v-for="(item, index) in fortuneBagCouponItemList"
          :key="index"
        >
          <div class="coupon-acquired-card">
            <div class="coupon-acquired-card-img" :style="{backgroundImage: 'url(' + item.cover_photo_url + ')'}" />
            <div class="coupon-acquired-card-text">
              <div class="coupon-acquired-card-description">{{ item.coupon_name }}</div>
              <div class="coupon-acquired-card-num">x{{ item.amount }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="el-button-padding">
        <el-button
          tag="button"
          type="button"
          class="el-button el-button--primary primary-button"
          @click="closeFortuneBagDialog()"
        >
          {{ $t('acquiredFortuneBagCouponsSuccessfulButtonText') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import config from '@/config'
import CouponItem from '@/components/CouponItem'
import utilsMixin from '@/mixins/utilsMixin'
import backHandlerMixin from '@/mixins/backHandlerMixin'

import CouponLoadingItem from '@/components/CouponLoadingItem.vue'
import { getCampaignlimit } from '@/helpers/http'
import { isWechatMiniProgram, pushRouterWithQuery, replaceRouterWithQuery } from '@/utils/utils'
import { sendPageViewAnalytics, sendManualAnalytics } from '@/utils/tracking'
import axios from 'axios'

export default {
  name: 'MyWallet',
  components: {
    CouponItem,
    CouponLoadingItem
  },
  mixins: [utilsMixin, backHandlerMixin],
  data: function () {
    return {
      voucherList: [],
      couponList: [],
      statusFilterList: [
        { label: this.$t('myWalletAllStatus'), value: 'All status' },
        { label: this.$t('myWalletAvailableAndUnavailable'), value: 'Available and Unavailable' },
        { label: this.$t('myWalletRedeemedExpired'), value: 'Redeemed and Expired' }
      ],
      fortuneBagCouponItemList: [],
      isShowFortuneBagCongratulationsDialog: false
    }
  },
  computed: {
    statusFilter: {
      get: function () {
        return this.$store.getters['coupons/query']
      },
      set: function (newValue) {
        console.log('new value', newValue)
        this.filterCoupons(newValue)
      }
    },
    isVoucherActive () {
      return this.$store.getters['coupons/couponType'] === 'VOUCHER_SECTION'
    },
    isCouponActive () {
      return this.$store.getters['coupons/couponType'] === 'COUPON_SECTION'
    },
    isTicketsActive () {
      return this.$store.getters['coupons/couponType'] === 'TICKET_SECTION'
    },
    isLimitedActive () {
      return this.$store.getters['coupons/couponType'] === 'LIMITED_OFFER_SECTION'
    },
    isAllActive () {
      return this.$store.getters['coupons/couponType'] === 'ALL'
    },
    couponType () {
      let couponType = this.$store.getters['coupons/couponType']
      if (couponType === 'ALL') {
        couponType = ['VOUCHER_SECTION', 'COUPON_SECTION', 'TICKET_SECTION']
      } else {
        couponType = [couponType]
      }
      return couponType
    },
    itemList () {
      return this.getItemList(this.couponType)
    },
    voucherSection () {
      return this.getItemList(['VOUCHER_SECTION'])
    },
    voucherLength () {
      return this.voucherSection.length
    },
    limitedOfferSection () {
      return this.getItemList(['LIMITED_OFFER_SECTION'])
    },
    limitedLength () {
      return this.limitedOfferSection.length
    },
    couponSection () {
      return this.getItemList(['COUPON_SECTION'])
    },
    couponsLength () {
      return this.couponSection.length
    },
    ticketSection () {
      return this.getItemList(['TICKET_SECTION'])
    },
    ticketsLength () {
      return this.ticketSection.length
    },
    allLength () {
      return this.voucherLength + this.limitedLength + this.couponsLength + this.ticketsLength
    },
    starredLength () {
      const starredVoucherLength = this.voucherSection.filter(item => {
        if (item.campaign && item.campaign.is_starred) {
          return true
        }
        return false
      }).length
      const starredLimitedOfferLength = this.limitedOfferSection.filter(item => {
        if (item.campaign && item.campaign.is_starred) {
          return true
        }
        return false
      }).length
      const starredCouponLength = this.couponSection.filter(item => {
        if (item.campaign && item.campaign.is_starred) {
          return true
        }
        return false
      }).length
      return starredVoucherLength + starredLimitedOfferLength + starredCouponLength
    },
    goodiePerHeadOwnStarredLimit () {
      let goodiePerHeadOwnStarredLimit = 1
      const campaignLimits = this.$store.getters['campaigns/getCampaignLimits']
      if (campaignLimits) {
        goodiePerHeadOwnStarredLimit = campaignLimits.star_campaign_per_head_owned_limit
      }
      return goodiePerHeadOwnStarredLimit
    },
    customerOwnedStarCampaignCouponQuantity () {
      let customerOwnedStarCampaignCouponQuantity = null
      const campaignLimits = this.$store.getters['campaigns/getCampaignLimits']
      if (campaignLimits) {
        customerOwnedStarCampaignCouponQuantity = campaignLimits.customer_owned_star_campaign_coupon_quantity
      }
      return customerOwnedStarCampaignCouponQuantity
    },
    discoverHref () {
      const lang = this.$i18n.locale
      if (lang === 'en') {
        return `${process.env.VUE_APP_DEALS_EN_URL}`
      }
      return `${process.env.VUE_APP_DEALS_TC_SC_URL}`
    }
  },
  watch: {
    '$store.state.app.OneTrustHeight': function () {
      if (!this.oneTrustHeight) {
        if (this.$store.getters['app/isMobile']) {
          window.$('.scroll-area').css('max-height', '58vh')
        } else {
          window.$('.scroll-area').css('max-height', '63vh')
        }
      }
    }
  },
  mounted: function () {
    if (this.$store.getters['auth/isShowCouponAlertFromOtherChannels']) {
      this.showGetSuccessfullMessage()
    }
    console.log('to, from 1', this.$store.getters['coupons/needInitQuery'])
    if (this.$store.getters['coupons/needInitQuery']) {
      this.$store.commit('coupons/setQuery', 'All status')
    }

    if (this.isBackNavigation) {
      const { statusFilter } = this.$store.getters['app/getLastMyWalletState']
      this.statusFilter = statusFilter
    }

    const { name } = this.$route
    console.log('@936', name)
    // special URL for CMS redirect
    if (name === 'my-tickets') {
      this.selectTickets()
    }
    if (name === 'my-coupons') {
      this.selectCoupons()
    }
    if (name === 'my-voucher') {
      this.selectVouchers()
    }
    if (name === 'my-limit-gift') {
      this.selectGifts()
    }
    if (name === 'my-wallet-all') {
      this.selectAll()
    }
    getCampaignlimit(this.$store)
    setTimeout(() => { this.$store.commit('app/couponItemLoadingFinish') }, 3000)

    const { sign } = this.$route.query
    console.log('@314', sign)
    if (sign) {
      this.isShowFortuneBagCongratulationsDialog = true
      this.getFortuneBagCouponItemList(sign)
      this.getGoodieBagDetail()
    }
    setTimeout(() => {
      if (this.$store.getters['app/getIsOneTrustDisplay']) {
        const oneTrustHeight = this.$store.getters['app/getOneTrustHeight']
        window.$('.acquire-success-dialog-body .scroll-area').css('max-height', 'calc(63vh - ' + oneTrustHeight + 'px)')
      }
    }, 600)
  },
  beforeDestroy () {
    this.$message.closeAll()
    this.$store.commit('auth/closeCouponAlertFromOtherChannels')
    console.log('@251', this.statusFilter)
    this.$store.commit('app/setLastMyWalletState', { statusFilter: this.statusFilter })
    this.$store.commit('app/setMiniProgramBackTitle', this.$t('toMyWallet'))
  },
  /*
  beforeRouteEnter (to, from, next) {
    next(vm => {
      setTimeout(() => {
        const { name } = vm.$route
        console.log('@936', name)
        if (name === 'my-coupons' && window.$('.menu-my-coupons').parent('.is-active').length === 0) {
          window.$('.menu-my-coupons').click()
        }

        if (name === 'my-wallet' && window.$('.menu-my-vouchers').parent('.is-active').length === 0) {
          window.$('.menu-my-vouchers').click()
        }
      }, 0)
    })
  },
  */
  methods: {
    changeTab (tabName) {
      if (tabName === 'my-voucher') {
        this.selectVouchers()
      } else if (tabName === 'my-coupons') {
        this.selectCoupons()
      } else if (tabName === 'my-tickets') {
        this.selectTickets()
      } else {
        this.selectAll()
      }

      // update path name
      const to = this.$i18nRoute(this.$route, tabName)
      replaceRouterWithQuery(this.$router, to)
    },
    directHome () {
      pushRouterWithQuery(this.$router, { name: 'home' })
    },
    filterCoupons (value) {
      console.log('value', value)
      this.$store.commit('coupons/setQuery', value)
    },
    selectVouchers () {
      this.$store.commit('coupons/setFilterCouponType', 'VOUCHER_SECTION')
    },
    selectCoupons () {
      this.$store.commit('coupons/setFilterCouponType', 'COUPON_SECTION')
    },
    selectTickets () {
      this.$store.commit('coupons/setFilterCouponType', 'TICKET_SECTION')
    },
    selectGifts () {
      this.$store.commit('coupons/setFilterCouponType', 'LIMITED_OFFER_SECTION')
    },
    selectAll () {
      this.$store.commit('coupons/setFilterCouponType', 'ALL')
    },

    captilize (status) {
      const result = status.charAt(0).toUpperCase() + status.slice(1)

      console.log('@141', result)
      return result
    },
    getItemList (couponType) {
      console.log('statusFilter', this.statusFilter)
      return this.$store.getters['coupons/couponList'](this.statusFilter, couponType)
    },
    async getFortuneBagCouponItemList (sign) {
      const ssoUid = this.$store.getters['auth/ssoUid']
      const url = `/customers/${ssoUid}/coupons?sort=-date_of_acquisition&page[size]=500&page[number]=1&sign=${sign}`
      const response = await axios.get(url)
      const itemList = response.data || []
      console.log('@404', itemList)
      const itemResutlDict = {}
      for (let i = 0; i < itemList.length; i++) {
        console.log('@407 @211', itemList[i].date_of_acquisition)
        if (itemList[i].campaign) {
          const resultKeys = Object.keys(itemResutlDict)
          const campaignId = itemList[i].campaign.id.toString()
          if (resultKeys.indexOf(campaignId) > -1) {
            itemResutlDict[itemList[i].campaign.id]['amount'] += 1
          } else {
            itemResutlDict[itemList[i].campaign.id] = {
              'cover_photo_url': itemList[i].template.cover_photo_url,
              'coupon_name': itemList[i].template.name,
              'amount': 1
            }
          }
        } else {
          itemResutlDict[itemList[i].id] = {
            'cover_photo_url': itemList[i].template.cover_photo_url,
            'coupon_name': itemList[i].template.name,
            'amount': 1
          }
        }
      }
      const itemResutlList = []
      for (const [key, value] of Object.entries(itemResutlDict)) {
        console.log('@429', key, value)
        itemResutlList.push(value)
      }
      itemResutlList.sort((a, b) => {
        if (a.coupon_name && b.coupon_name) {
          return a.coupon_name.toLowerCase() > b.coupon_name.toLowerCase() ? 1 : -1
        }
        return 0
      })
      this.fortuneBagCouponItemList = itemResutlList
    },
    isWechatMiniProgram () {
      return isWechatMiniProgram()
    },
    closeFortuneBagDialog () {
      this.isShowFortuneBagCongratulationsDialog = false
      const query = Object.assign({}, this.$route.query)
      delete query.sign
      replaceRouterWithQuery(this.$router, { query })
      // track click click_done_on_goodiebag(fortunebag)_success_pop_up
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'click_done_on_goodiebag(fortunebag)_success_pop_up', 'onclick', {})
    },

    async getGoodieBagDetail () {
      const { goodie_bag_id: goodieBagId } = this.$route.query
      const goodieBagDetail = this.$store.getters['goodiebags/goodieBagDetail'](goodieBagId)
      if (!goodieBagDetail) {
        try {
          await this.$store.dispatch('goodiebags/updateGoodieBag', goodieBagId)
        } catch (error) {
          console.log('get goodie bag detail error')
        }
      }
      // track pv acquire_goodie_bag_success_pop_up
      sendPageViewAnalytics(this.$route, this.$i18n.locale, this.$store, { 'popupType': 'acquire_goodie_bag_success_pop_up' })
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/coupon-tab-menu.scss';

/deep/ .menu-select .el-input__inner {
  color: #E2002C;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}

/deep/ .menu-select .el-input {
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(83, 83, 83, 0.2);
  border-radius: 4px;
}

.coupon-loading-title{
  width: 100px;
  height: 19px;
  background-color: #D8D8D8;
  margin-left: 11px;
  margin-top: 9.4px;
}

.coupon-loading-text-1{
  background: #D8D8D8;
  height: 19px;
  margin-top: 30px;
  margin-left: 11px;
  margin-right: 11px;
}

.coupon-loading-text-2{
  background: #D8D8D8;
  height: 19px;
  max-width: 154px;
  margin-left: 11px;
  margin-right: 11px;
  margin-top: 6px;
}

.coupon-loading-logo{
  height: 42px;
  width: 42px;
  background: #D8D8D8;
}

.coupon-merchant-icon {
  width: 50px;
  height: 50px;
}

.mywallet-banner {
    margin-top: 10px;
    margin-bottom: 10px;
}

.coupon-container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  //justify-content: space-evenly;
}

.discover-container{
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-top: 105px;
  .description-1{
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    margin-top: 20px;
    color: #000000;
  }
  .description-2{
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    margin-top: 6px;
    color: #E2002C;
    text-decoration: underline;
    cursor: pointer;
  }
  img{
    margin-left: 30px;
  }
}

.starred-row{
  background: #FFE8E3;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  align-items: center;
  .starred-label{
    border: 1px solid #FF580A;
    border-radius: 3px;
    color: #FF580A;
    font-weight: 700;
    font-size: 14px;
    padding-left: 6px;
    padding-right: 6px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    flex: none;
  }
  .line{
    color: #666666;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-left: 8px;
  }
}

.is-vue-desktop .coupon-loading .coupon-header{
  height: 170px;
  background: #D8D8D8;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.is-vue-mobile{
  .starred-row{
    flex-flow: column;
    align-items: flex-start;
    margin-top: -17px;
    margin-bottom: 16px;
    padding-left: 12px;
    padding-right: 12px;
    .line{
      margin-top: 8px;
      margin-left: 0px;
      font-size: 14px;
    }
  }
  .my-wallet-title{
    font-weight: bold;
    font-size: 22px;
    color: #000000;
    margin-left: 16px;
  }
  .coupon-container {
    margin-left: 4px;
    margin-right: 4px;
  }
  .menu-container {
    display: flex;
    flex-flow: column;
    background-color: #fff;
    margin-top: -24px;
    padding-top: 24px;
    padding-bottom: 16px;
  }
  .menu-row{
    margin-bottom: 7px;
  }
  .menu-row .menu-text{
    font-size: 14px;
    line-height: 18px;
    margin-left: 0px;
  }
  .menu-row .menu-item{
    height: 18px;
    margin-right: 0px;
    text-transform: uppercase;
    margin-top: 17px;
  }
  .menu-item{
    color: #999999;
  }

  .select-row{
    padding-left: 12px;
    padding-right: 12px;
    .menu-select{
      width: 100%;
      margin-bottom: 16px;
    }
  }

  .discover-container{
    min-height: calc(100vh - 570px);
    padding-left: 13px;
    padding-right: 13px;
    margin-top: 0px;
    .description-1{
      font-size: 18px;
      line-height: 25px;
      color: #000000;
    }
    .description-2{
      margin-top: 6px;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #E2002C;
    }
    img{
      margin-left: 20px;
    }
  }

}

.is-vue-desktop {
  .my-wallet-title{
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    color: #000000;
    margin-left: 13px;
    margin-bottom: 20px;
  }
  .back-to-top{
    display: none;
  }

  .menu-select{
    min-width: 347px;
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 25px;
  }

  .select-row{
    display: flex;
    justify-content: right;
  }

  .coupon-header {
    width: 100%;
    height: 150px;
    position: relative;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-size:cover;
  }

  .coupon-tag {
    top: 17px;
    left: 17px;
    padding: 5px;
    // width: 102px;
    // height: 21px;
    font-weight: 800;
    font-size: 10px;
    line-height: 11px;
  }

  .coupon-expiry-title {
    margin-left: 15px;
    margin-top: 11px;
    color: #999999;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
  }

  .coupon-expiry-date {
    margin-left: 15px;
    margin-top: 4px;
    font-size: 15px;
    line-height: 24px;
    color: #FE8634;
    text-transform: capitalize;
  }

  .coupon-title {
    margin-left: 15px;
    margin-top: 5.5px;
    height: 71px;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    overflow-y:hidden;
    padding-right: 15px;
  }

  .coupon-line {
    height: 1px;
    margin-top: 11px;
    border-bottom: solid 1px #DADADA;
  }

  .coupon-merchant-container {
    display: flex;
    align-items: center;
    margin-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .coupon-merchant-icon {
    flex: 0 0 42px;
    height: 42px;
  }

  .coupon-merchant-name {
    margin-left: 10px;
    height: 36px;
    overflow: hidden;
    font-size: 13px;
    line-height: 18px;
    padding-right: 19px;
  }

  .menu-container {
    display: flex;
    flex-flow: column;
    margin-bottom: 20px;
  }

  .coupon-item{
    width: calc(25% - 20px);
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 0px 10px rgba(164, 164, 164, 0.29);
    margin-left: 0;
    margin-right: 20px;
    margin-bottom: 32px;
  }

  .coupon-item:nth-child(4n) {
    margin-right: 0;
  }

  .menu-row {
    display:flex;
    justify-content: space-between;
    padding-top: 30px;
    background: #FFFFFF;
    padding-left: 17px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .menu-row .menu-container {
    margin-bottom: 20px;
  }
  .menu-row .menu-item {
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    margin-right: 20px;
    display: inline-block;
    text-transform: uppercase;
  }

  .menu-item {
    color: #999999;
  }

  .menu-bar {
    width: 4px;
    height: 28px;
    background-color: #E2002C;
    position: absolute;
  }

  .menu-text {
    margin-left: 13px;
  }

  .coupon-container {
    margin-left: 20px;
  }

}

.back-to-top {
  margin-right: 25px;
  margin-bottom: 25px;
}
</style>
<style lang="scss">
.document-my-wallet .menu-select.el-select .el-input .el-select__caret{
  color: #61666A;
  font-weight: 900;
}
.document-my-wallet  .menu-select.el-select input{
  height: 45px;
}
.is-vue-mobile{
  .document-my-wallet-all, .document-my-limit-gift, .document-my-voucher, .document-my-coupons, .document-my-wallet{
    .back-top-placeholder{
      height: 32px;
    }
    .main-container .el-backtop{
      margin-top: 0px;
    }
  }
}
.document-my-wallet-all, .document-my-limit-gift, .document-my-voucher, .document-my-coupons, .document-my-wallet{
  .starred-row .line b{
    font-size: 15px;
  }
}
/* acuired the coupon(s) success dialogcss */
.fortune-bag-acquire-success-dialog{
  .acquire-success-dialog-body{

  }
    .el-dialog{
      border-radius: 10px;
    }
    .el-dialog__body{
      padding: 4px 8px 24px 16px;
      text-align: center;
    }
    .scroll-area{
      max-height: 75vh;//desktop
      overflow-y: scroll;
      padding-right: 4px;
    }
    .scroll-area::-webkit-scrollbar{
      width: 4px;
    }
    .scroll-area::-webkit-scrollbar-track {
      display: none;
    }
    .scroll-area::-webkit-scrollbar-thumb {
      border-radius:2px;
      -webkit-box-shadow: inset 0 0 6px #DADADA;
      margin-right: 16px;
    }
    .acquire-success-title{
      color: #000;
      text-align: center;
      font-weight: 700;
      font-size: 18px;
    }
    .coupon-found-text{
      color: #000;
      font-weight: 400;
      margin-bottom: 16px;
      font-size: 14px;
      margin-top: 8px;
    }
    .coupon-acquired-card{
      height: 90px;
      border-radius: 10px;
      display: flex;
      background-color: #f7f7f7;
      margin-bottom: 8px;

      .coupon-acquired-card-img{
        width: 165px;
        border-radius: 10px 0 0 10px;
        background-size: cover;
        background-position: center;
      }
      .coupon-acquired-card-text{
        flex: 1.5;
        border-radius: 0 10px 10px 0;
        padding: 8px
      }
      .coupon-acquired-card-text .coupon-acquired-card-description{
        height: 80%;
        font-size: 14px;
        line-height: 130%;
        font-weight: 600;
        color: #000;
        text-align: left;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

      }
      .coupon-acquired-card-text .coupon-acquired-card-num{
        color: #E2002C;
        text-align: right;
        font-size: 14px;
        font-weight: 600;
        line-height: 130%;
      }
    }
    .acquire-success-img img{
      height: 80px;
    }
    .acquire-success-img{
      position: relative;
      padding-right: 14px;
      width: 100%;
    }
    .el-button-padding{
      padding-right: 16px;
    }
    .primary-button{
      margin-top: 16px;
      width: 100%;
      border-radius: 50px;
      padding: 10px 30px;
      font-size: 18px;
      font-weight: 700;
    }
  }
.is-vue-mobile{
  .fortune-bag-acquire-success-dialog{
    .el-dialog__body{
      padding: 4px 8px 24px 16px;
    }
    .acquire-success-title{
      font-size: 18px;
    }
    .scroll-area{
      max-height: 58vh;
      padding-right: 4px;
    }
    .coupon-acquired-card{
      height: 90px;
      margin-bottom: 8px;
      .coupon-acquired-card-img{
        width: 120px;
      }
      .coupon-acquired-card-text{
        flex: 1.5;
      }
      .coupon-acquired-card-text .coupon-acquired-card-description{
        height: 78%;
        font-size: 14px;
        line-height: 130%;
        -webkit-line-clamp: 3;
      }
      .coupon-acquired-card-text .coupon-acquired-card-num{
        color: #E2002C;
        text-align: right;
        font-size: 14px;
        font-weight: 600;
        line-height: 130%;
      }
    }
    .acquire-success-img img{
      height: 80px;
    }
    .acquire-success-img{
      margin-top: -92px;
    }
    .primary-button{
      margin-top: 16px;
      width: 100%;
    }
  }
}
.is-vue-desktop{
  .fortune-bag-acquire-success-dialog{
    .el-dialog__body{
      padding: 2px 16px 32px 30px;
    }
    .acquire-success-title{
      font-size: 30px;
    }
    .scroll-area{
      max-height: 63vh;
      padding-right: 10px;
    }
    .coupon-found-text{
      margin-bottom: 24px;
    }
    .coupon-acquired-card{
      height: 124px;
      margin-bottom: 16px;
      .coupon-acquired-card-img{
        width: 165px;
      }
      .coupon-acquired-card-text{
        flex: 3;
      }
      .coupon-acquired-card-text .coupon-acquired-card-description{
        height: 84%;
        font-size: 15px;
        line-height: 24px;
        -webkit-line-clamp: 4;
      }
    }
    .acquire-success-img img{
      height: 120px;
    }
    .acquire-success-img{
      margin-top: -121px;
    }
    .primary-button{
      margin-top: 24px;
      width: 65%;
    }
  }
}

</style>
