<template>
  <div
    id="app"
    :class="[{'is-vue-page': true, 'is-vue-mobile': $store.getters['app/isMobile'],'is-vue-desktop': !$store.getters['app/isMobile']}]"
  >
    <router-view />
  </div>
</template>

<script>
import store from './store'
import { i18n } from './i18n'
const Bowser = require('bowser')
import { sendPageViewAnalytics } from '@/utils/tracking'

export default {
  name: 'App',
  created () {
    window.addEventListener('resize', this.resizeHandler)
    this.resizeHandler() // trigger for the first time
    this.prepareCollapsible()
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeHandler)
  },
  mounted () {
    this.$router.afterEach((to, from) => {
      console.log('@36, route changed')
      if (!(from.name === 'campaign-detail' && to.name === 'home')) {
        window.scrollTo(0, 0)
      }

      console.log('@router@106@36b', to)

      sendPageViewAnalytics(to, i18n.locale, store)
    })
    console.log('this support brower', this.isSupportBrowser())
  },
  methods: {
    isSupportBrowser () {
      return ['chrome', 'firefox', 'msie', 'safari'].indexOf(Bowser.name.toLowerCase()) > -1
    },
    resizeHandler (e) {
      this.$store.commit('app/setWindowWidth', window.innerWidth)
      this.$store.commit('app/setWindowHeight', window.innerHeight)
      if (window.$('#onetrust-banner-sdk:visible').height()) {
        this.$store.commit('app/setOneTrustHeight', window.$('#onetrust-banner-sdk').height())
      }
    // your code for handling resize...
    },
    prepareCollapsible () {
      window.$('document').find('.collapse-button').click(function () {
        window.$(this).parents('.is-collapsible').toggleClass('is-collapsed')
      })
    }
  }
}
</script>

<style lang='scss'>
#app {
 background-color: #F7F7F7;
 min-height: 100vh;
 font-family: Open Sans, Microsoft JHei TC Regular, arial, sans-serif;
}
.el-scrollbar {
    > .el-scrollbar__bar {
        opacity: 1;
    }
}
</style>
