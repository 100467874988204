<template>
  <div class="main-container">
    <div class="header-container">
      <div class="page-title-container">
        <div class="page-title">{{ $t('myWalletMyVouchers') }}</div>
        <el-tooltip
          class="item"
          :content="$t('goodiebagTreatReward')"
          placement="bottom"
          @mouseover.native="analyticsMouseOver()"
        >
          <img
            class="question-mark-icon"
            srcset="/img/info.1x.png, /img/info.2x.png 2x, /img/info.3x.png 3x"
            src="/img/info.2x.png"
          >
        </el-tooltip>
      </div>

      <router-link
        :to="$i18nRoute($route, 'my-wallet-all')"
        data-aid="goodie_bag_myrewards_checkmywallet_click"
        class="my-wallet-container"
        tag="div"
      >
        <img
          class="wallet-icon"
          srcset="/img/my_wallet_new.1x.png, /img/my_wallet_new.2x.png 2x, /img/my_wallet_new.3x.png 3x"
          src="/img/my_wallet_new.2x.png"
        >
        <div class="info-container">
          <div class="wallet-title" v-html="$t('goodiebagMyRewardCheckmyWallet')" />
          <div class="wallet-description">{{ $t('goodiebagFindredemption') }}</div>
        </div>
        <img
          class="more-arrow-icon"
          srcset="/img/more-arrow.1x.png, /img/more-arrow.2x.png 2x, /img/more-arrow.3x.png 3x"
          src="/img/more-arrow.2x.png"
        >
      </router-link>
    </div>

    <div class="goodie-bag-list-container">
      <div
        class="goodie-bag-list"
        :style="goodieBagListStyle"
      >
        <div v-if="initFinished && !(itemList && itemList.length)" class="goodie-bag-empty-list">
          <div class="empty-reward-image">
            <img
              class="empty-reward-icon"
              src="/img/my_reward_empty_image.png"
            >
          </div>
          <div class="empty-reward-title" v-html="emptyRewardWords.Title"></div>
          <div v-if="emptyRewardWords.Link" class="empty-reward-desc-link" v-html="emptyRewardWords.Desc"></div>
          <div v-else class="empty-reward-desc" v-html="emptyRewardWords.Desc"></div>
        </div>
        <goodie-bag-list-item
          v-for="item in itemList"
          :key="item.id"
          :item="item"
          @showTnc="toggleTncDialog"
        />
      </div>
      <div
        :class="['goodie-bag-list-overlay', 'left', {active: showLeftArrow}]"
        @click="moveLeft"
      >
        <div class="arrow-btn">
          <img
            class="arrow-icon"
            srcset="/img/arrow-icon.1x.png, /img/arrow-icon.2x.png 2x, /img/arrow-icon.3x.png 3x"
            src="/img/arrow-icon.2x.png"
          >
        </div>
      </div>
      <div
        :class="['goodie-bag-list-overlay', 'right', {active: showRightArrow}]"
        @click="moveRight"
      >
        <div class="arrow-btn">
          <img
            class="arrow-icon"
            srcset="/img/arrow-icon.1x.png, /img/arrow-icon.2x.png 2x, /img/arrow-icon.3x.png 3x"
            src="/img/arrow-icon.2x.png"
          >
        </div>
      </div>
    </div>

    <div class="goodie-bag-carousel-container">
      <agile-banner
        :height="isMobile ? 120: 150"
        :width="isMobile ? 343: 964"
        :banners="banners"
      />
    </div>
    <terms-dialog
      v-if="tncItem"
      v-model="showTnc"
      :title="tncItem.goodie_bag.generic_terms_and_conditions_title"
      :content="tncItem.goodie_bag.generic_terms_and_conditions_content"
    />
  </div>
</template>

<script>
import GoodieBagListItem from '@/components/GoodieBagListItem'
import TermsDialog from '@/components/TermsDialog'
import AgileBanner from '@/components/AgileBanner'
import { sendManualAnalytics } from '../utils/tracking'
import { isWechatMiniProgram } from '@/utils/utils'
// import { replaceRouterWithQuery } from '@/utils/utils'

export default {
  name: 'MyReward',
  components: {
    GoodieBagListItem,
    TermsDialog,
    AgileBanner
  },
  data () {
    return {
      index: 0,
      tncItemId: 0,
      showTnc: false
    }
  },
  computed: {
    isMobile () {
      return this.$store.getters['app/isMobile']
    },
    hasGoodieBag () {
      return this.$store.getters['goodiebagparticipationrecords/hasRecord']
    },
    initFinished () {
      return this.$store.getters['goodiebagparticipationrecords/initFinished']
    },
    banners () {
      const banners = this.$store.getters['app/getMyRewardBanners']
      return banners && banners.length > 0 ? banners.slice(0, 5) : []
    },
    emptyRewardWords () {
      if (isWechatMiniProgram()) {
        return {
          Title: this.$t('myRewardEmptyTitle'),
          Desc: this.$t('myRewardEmptyDesc'),
          Link: ''
        }
      }
      return {
        Title: this.$t('myRewardEmptyTitle'),
        Desc: this.$t('myRewardEmptyDesc'),
        Link: ''
      }
    },
    showLeftArrow () {
      return this.index > 0
    },
    showRightArrow () {
      return this.itemList && this.itemList.length - this.index >= 3
    },
    goodieBagListStyle () {
      let offset = this.index * 373
      console.log('goodieBagListStyle', this.itemList.length - this.index)
      if (this.itemList && this.itemList.length - this.index === 2) {
        offset -= 248
      }
      return { transform: `translateX(-${offset}px)` }
    },
    itemList () {
      return this.$store.getters['goodiebagparticipationrecords/recordList'](this.$store.getters['auth/isTestUser'])
    },
    tncItem () {
      const { 0: tncItem } = this.itemList.filter(item => item.id === this.tncItemId)
      console.log('tncItem', tncItem)
      return tncItem
    },
    redeemedRecords () {
      let redeemedRecords = []
      const { record_id: recordIdList } = this.$route.query
      if (recordIdList) {
        redeemedRecords = recordIdList.split(',').map((recordId) =>
          this.$store.getters['goodiebagparticipationrecords/recordDetail'](recordId)
        )
      }
      return redeemedRecords.filter(x => !!x)
    },
    redeemedGoodieBagIDList () {
      return this.redeemedRecords.map(({ goodie_bag: goodieBag }) => goodieBag.id).sort().join()
    }
  },
  watch: {
    'initFinished': function (newVal, oldVal) {
      // if (newVal && newVal !== oldVal) {
      //   this.$nextTick(function () {
      //     if (!this.hasGoodieBag) {
      //       replaceRouterWithQuery(this.$router, { name: 'home' })
      //     }
      //   })
      // }
    },
    'redeemedGoodieBagIDList': function (newVal, oldVal) {
      const { response_code: responseCode } = this.$route.query
      if (newVal !== oldVal && responseCode === '201') {
        this.$nextTick(function () {
          this.showMessage()
        })
      }
    }
  },
  beforeDestroy () {
    this.$store.commit('auth/closeGoodieAlertFromOtherChannels')
    this.$store.commit('app/setMiniProgramBackTitle', this.$t('toMyRewards'))
  },
  methods: {
    analyticsMouseOver () {
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'goodie_bag_myrewards_info_click', 'onclick')
    },
    toggleTncDialog (tncItemId) {
      this.tncItemId = tncItemId
      this.showTnc = !this.showTnc
    },
    moveLeft () {
      if (this.index > 0) {
        this.index -= 1
      }
    },
    moveRight () {
      if (this.itemList && this.itemList.length - this.index >= 3) {
        this.index += 1
      }
    },
    showMessage () {
      if (!this.redeemedRecords || this.redeemedRecords.length === 0) {
        return
      }

      /* const { 0: {
        goodie_bag: {
          // name,
          id: goodie_bag_id
          // assign_quota_per_link: quota,
          // assign_credit_per_link: credit,
          // reward_type: rewardType
        }
      }} = this.redeemedRecords*/

      console.log('@777', this.redeemedRecords)

      const message = this.$t('goodiebagSuccessfullyUnlockedXBoth')
      /*
      if (this.redeemedRecords.length === 1) {
        if (rewardType === 'BOTH') {
          message = this.$t('goodiebagSuccessfullyUnlockedBoth', { name, quota, credit })
        } else if (rewardType === 'CASH_VOUCHER') {
          message = this.$t('goodiebagSuccessfullyUnlockedCash', { name, credit })
        } else {
          message = this.$t('goodiebagSuccessfullyUnlockedGift', { name, quota })
        }
      } else {
        const count = this.redeemedRecords.length
        console.log('goodiebagSuccessfullyUnlockedXBoth', this.redeemedRecords, count, name, quota, credit)
        if (rewardType === 'BOTH') {
          message = this.$t('goodiebagSuccessfullyUnlockedXBoth', { count, name, quota: quota * count, credit: credit * count })
        } else if (rewardType === 'CASH_VOUCHER') {
          message = this.$t('goodiebagSuccessfullyUnlockedXCash', { count, name, credit: credit * count })
        } else {
          message = this.$t('goodiebagSuccessfullyUnlockedXGift', { count, name, quota: quota * count })
        }
      }*/

      let customClass = 'goodiebag-unlock-success'
      let offset = 73
      if (this.$store.getters['app/isMobile']) {
        customClass += ' mobile'
        offset = 61
      }

      // sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'acquire_goodie_bag_success', 'event', { 'id': goodie_bag_id })
      this.$message({
        type: 'success',
        offset,
        duration: 5000,
        dangerouslyUseHTMLString: true,
        message,
        customClass
      })

      console.log('@266')
      /*
      const query = Object.assign({}, this.$route.query)
      delete query.record_id
      delete query.response_code
      replaceRouterWithQuery(this.$router, { query })
      */
    }
  }

}
</script>

<style lang="scss">
.el-message.goodiebag-unlock-success {
  background: #E6F4E7;
  border: 1px solid rgba(128, 189, 120, 0.1);

  .el-message__icon{
    font-size: 20px;
  }

  .el-message__content {
    color: #000000;
  }

  &.mobile {
    border-radius: 6px;
    min-width: calc(100vw - 40px);

    .el-message__content {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

/*
@media screen and (max-width: 1024px) {
  .el-tooltip__popper.is-dark[x-placement^="bottom"] {
    right: 22px;
  }
}
*/

.el-tooltip__popper.is-dark[x-placement^="bottom"] {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  font-size: 14px;
  line-height: 22px;
}

.is-vue-desktop .goodie-bag-carousel-container{
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 60px;
}

.goodie-bag-carousel-container {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 24px;
}
</style>

<style lang="scss" scoped>
.is-vue-desktop {
  .main-container {
    background-color: #F2F3F6;

    .header-container {
      background: linear-gradient(180deg, #FFFFFF 80%, #F7F7F7 20%);
    }

    .goodie-bag-empty-list {
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-bottom: 20px;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;

      .empty-reward-image {
        height: 120px;
        padding-left: 27px;
        align-self: center;
      }

      .empty-reward-title {
        margin-top: 20px;

        color: var(--Black, #000);
        text-align: center;

        /* Desktop_Title1 */
        font-family: Open Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .empty-reward-desc-link {
        margin-top: 6px;

        color: #E2002C;
        text-align: center;
        font-family: Open Sans;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 160% */
        text-decoration-line: underline;
      }
      .empty-reward-desc {
        margin-top: 6px;

        color: #666;
        text-align: center;
        font-family: Open Sans;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 160% */
      }
    }

    .goodie-bag-list {
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 21px;
    }

    .header-container {
      height: 189px;
      padding-top: 28px;
      padding-left: 30px;
      padding-bottom: 20px;
      padding-right: 30px;
      display: flex;
      flex-direction: column;

      .page-title-container {
        margin-bottom: 30px;

        .page-title {
          font-size: 30px;
          line-height: 41px;
        }
      }

      .my-wallet-container{
        cursor: pointer;
        width: 600px;
        align-self: center;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 30px;
        padding-right: 30px;
        min-height: unset;

        .info-container{

          .wallet-title {
            font-size: 20px;
            line-height: 27px;
          }

          .wallet-description{
            font-size: 15px;
            line-height: 22px;
            padding-right: 60px;
          }
        }
      }
    }
  }

}

.main-container{
  .goodie-bag-list-overlay{
    display: none;
  }

  .goodie-bag-empty-list {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    .empty-reward-image {
      height: 120px;
      padding-left: 27px;
      align-self: center;
    }

    .empty-reward-title {
      margin-top: 20px;

      color: var(--Black, #000);
      text-align: center;

      /* Desktop_Title1 */
      font-family: Open Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .empty-reward-desc-link {
      margin-top: 6px;

      color: #E2002C;
      text-align: center;
      font-family: Open Sans;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 160% */
      text-decoration-line: underline;
    }
    .empty-reward-desc {
        margin-top: 6px;

        color: #666;
        text-align: center;
        font-family: Open Sans;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 160% */
      }
  }

  .goodie-bag-list {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 21px;
  }

  .header-container {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px;
    padding-bottom: 24px;
    background: #FFFFFF;

    .my-wallet-container{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      min-height: 80px;
      box-shadow: 0px 1px 4px 1px rgba(143, 143, 143, 0.25);
      border-radius: 10px;
      padding-left: 20px;
      padding-right: 16px;
      background-color: #FFFFFF;

      .info-container{
        flex: 1;
        padding-left: 14px;
        padding-right: 14px;

        .wallet-title {
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          color: #000000;
          display: flex;
          align-items: center;
          b{
            color: #E2002C;
          }
        }

        .wallet-description{
          font-size: 14px;
          line-height: 20px;
          color: #000000;
        }
      }
    }

    .page-title-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;

      .page-title {
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        color: #000000;
        margin-right: 5px;
      }

      .question-mark-icon:focus,  .question-mark-icon:hover {
        outline-style: none;
      }
    }

  }

}

</style>
