<template>
  <div />
</template>
<script>
import { doLogout, getSsoAuthClient } from '../helpers/http'
import jwt_decode from 'jwt-decode'
import { getLangCode } from '@/utils/lang'
import utilsMixin from '@/mixins/utilsMixin'
import { isWechatMiniProgram, replaceRouterWithQuery } from '@/utils/utils'

// import axios from 'axios'
export default {
  name: 'VerifyCode',
  mixins: [utilsMixin],
  beforeRouteEnter (to, from, next) {
    console.log('to from', to, from)
    if (from.name === 'campaign-detail') {
      next({ name: 'home' })
    } else {
      next()
    }
  },
  mounted () {
    console.log('1212verify code', this.$route.query)
    const ssoAuth = getSsoAuthClient(this.$route.query.state)
    let url = null
    let state = {}
    try {
      state = JSON.parse(this.$route.query.state)
    } catch (error) {
      console.log('error: ', error)
    }
    url = state.url
    console.log('20 url', url)
    if (!url || typeof url !== 'string') {
      const lang = this.$i18n.locale
      url = `/${lang}/home`
    }
    const info = state.info
    console.log('23--- url', url, state, info)

    // const testUrl = 'http://localhost:8080/verifycode' + window.location.search
    ssoAuth.code.getToken(window.location.href).then((response) => {
      console.log('response---', response)
      const { data } = response
      const { access_token: accessToken, refresh_token: refreshToken } = data
      localStorage.setItem('refreshToken', refreshToken)
      localStorage.setItem('accessToken', accessToken)
      this.$store.commit('auth/loginUser', { accessToken })

      if (isWechatMiniProgram()) {
        const wechatTokenURL = window.location.href.split('#')[0]
        this.$store.commit('app/setWechatTokenURL', wechatTokenURL)
      }

      console.log('@43', accessToken)
      console.log('@46', JSON.stringify(jwt_decode(accessToken)))
      const payload = jwt_decode(accessToken)
      if (payload && payload.ext && payload.ext.login_langauge && url.startsWith('/')) {
        console.log('@48, redirect by token')
        const lang = getLangCode(payload.ext.login_langauge)

        const urlParts = url.split('/')

        urlParts[1] = lang

        url = urlParts.join('/')
      }

      // url is full url
      if (url.startsWith('http')) {
        window.location.replace(url)
      } else {
        replaceRouterWithQuery(this.$router, { path: url, query: info })
      }
    }).catch(error => { // otherwise verify code will show blank screen if code is blank
      console.log('cannot get token: ', error)
      doLogout()
    })
  }
}
</script>
