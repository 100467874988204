<template>
  <div class="goodie-bag-detail">
    <img
      class="gift-header"
      :class="{'expired':isExpired}"
      srcset="/img/gift-header.1x.png, /img/gift-header.2x.png 2x, /img/gift-header.3x.png 3x"
      src="/img/gift-header.2x.png"
    >
    <img
      class="gift-header desktop"
      :class="{'expired':isExpired && !isTestUser}"
      srcset="/img/gift-header-desktop.1x.png, /img/gift-header-desktop.2x.png 2x, /img/gift-header-desktop.3x.png 3x"
      src="/img/gift-header-desktop.2x.png"
    >
    <div
      class="goodie-bag-container"
    >
      <div class="goodie-bag-banner-container">
        <img
          :is="coverPhotoUrl ? 'img' : 'div'"
          class="goodie-bag-banner"
          :src="coverPhotoUrl"
        />
        <div class="goodie-bag-title-container">
          <div class="goodie-bag-title">
            {{ goodieBag.name }}
          </div>
        </div>
      </div>

      <div
        class="goodie-bag-info-container"
        :style="{background: item.backgroud_color}"
      >
        <div class="goodie-bag-title-container">
          <div class="goodie-bag-title">
            {{ goodieBag.name }}
          </div>
        </div>
        <div
          class="effective-date-container"
          v-html="goodieBagValidDateString"
        />
        <div
          v-for="campaignSlot in campaignSlots"
          :key="campaignSlot.id"
        >
          <router-link
            v-if="['CASH_VOUCHER', 'BOTH'].includes(campaignSlot.reward_type)"
            tag="div"
            :data-aid="$route.name === 'home'?'home_myrewards_rewards_click':'goodie_bag_myrewards_rewards_click'"
            data-atype="CASH_VOUCHER_CAMPAIGN_FOR_GOODIE_BAG"
            :to="$i18nRoute($route, 'goodie-bag-campaign-list', null, {recordId: item.id, goodieBagId: item.goodie_bag.id, type: 'CASH_VOUCHER_CAMPAIGN_FOR_GOODIE_BAG', from: $route.name, slotId: campaignSlot.id})"
            class="button-container"
          >
            <div class="button-detail">
              <div>
                <div class="button-description">
                  <div class="description">{{ creditName(campaignSlot) }}</div>
                  <img
                    class="red-arrow-right"
                    srcset="/img/red-arrow-right.1x.png, /img/red-arrow-right.2x.png 2x, /img/red-arrow-right.3x.png 3x"
                    src="/img/red-arrow-right.2x.png"
                  >
                </div>
                <div
                  class="button-title"
                  v-html="$t('goodiebagCreditTitle', {owned: campaignSlot.owned_reward>0?campaignSlot.owned_reward:0})"
                />
              </div>
              <img
                class="cash-voucher-icon"
                src="/img/cash-voucher.gif"
              >
            </div>
          </router-link>
          <router-link
            v-if="['GIFT', 'BOTH'].includes(campaignSlot.reward_type)"
            tag="div"
            :data-aid="$route.name === 'home'?'home_myrewards_rewards_click':'goodie_bag_myrewards_rewards_click'"
            data-atype="GIFT_CAMPAIGN_FOR_GOODIE_BAG"
            :to="$i18nRoute($route, 'goodie-bag-campaign-list', null, {recordId: item.id, goodieBagId: item.goodie_bag.id, type: 'GIFT_CAMPAIGN_FOR_GOODIE_BAG', from: $route.name, slotId: campaignSlot.id})"
            class="button-container gift"
          >
            <div class="button-detail">
              <div>
                <div class="button-description">
                  <div class="description">{{ quotaName(campaignSlot) }}</div>
                  <img
                    class="red-arrow-right"
                    srcset="/img/red-arrow-right.1x.png, /img/red-arrow-right.2x.png 2x, /img/red-arrow-right.3x.png 3x"
                    src="/img/red-arrow-right.2x.png"
                  >
                </div>
                <div
                  class="button-title"
                  v-html="$t('goodiebagQuotaTitle', {owned: campaignSlot.owned_reward>0?campaignSlot.owned_reward:0})"
                />
              </div>
              <img
                class="cash-voucher-icon"
                src="/img/gift.gif"
              >
            </div>
          </router-link>
        </div>
        <router-link
          class="find-container"
          :to="$i18nRoute($route, 'my-wallet', null, null)"
        >
          <div class="label">{{ $t('findYourAcquiredReward') }}</div>
          <img
            width="16px"
            srcset="/img/white-arrow-right.1x.png, /img/white-arrow-right.2x.png 2x, /img/white-arrow-right.3x.png 3x"
            src="/img/white-arrow-right.2x.png"
          >
        </router-link>
        <div
          v-if="hasTnc"
          class="tnc-container"
          :data-aid="$route.name === 'home'?'home_myrewards_tnc_click':'goodie_bag_myrewards_tnc_click'"
          :data-grid="item.id"
          @click="toggleTncDialog"
        >
          {{ $t('goodiebagEntitlementLink1') }}
        </div>
      </div>
    </div>
    <div
      v-if="isExpired && !isTestUser"
      class="expired-overlay"
    />
    <div
      v-if="isExpired && !isTestUser"
      class="goodie-bag-tag"
    >
      {{ $t('goodiebagExpired') }}
    </div>
    <div
      v-else-if="item.recently_added"
      class="goodie-bag-tag"
    >
      {{ $t('goodiebagRecentlyAdded') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'GoodieBagListItem',
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  computed: {
    hasTnc () {
      const {
        generic_terms_and_conditions_title: title,
        generic_terms_and_conditions_content: content
      } = this.goodieBag || {}
      return title || content
    },
    isTestUser () {
      return this.$store.getters['auth/isTestUser']
    },
    coverPhotoUrl () {
      let imageField = 'cover_photo_url'
      if (this.$store.getters['app/isMobile']) {
        imageField = 'mobile_photo_url'
      }
      const { [imageField]: coverPhotoUrl } = this.goodieBag || {}
      return coverPhotoUrl
    },
    goodieBag () {
      const { goodie_bag: goodieBag } = this.item
      return goodieBag
    },
    campaignSlots () {
      const { campaign_slots: campaignSlots } = this.item
      return campaignSlots
    },
    isExpired () {
      const { coupon_acquire_end_date: expiryDate } = this.item
      console.log('expiryDate', expiryDate)
      return expiryDate && new Date() > new Date(expiryDate)
    },
    goodieBagValidDateString () {
      const {
        coupon_acquire_start_date: startDate,
        coupon_acquire_end_date: endDate
      } = this.item
      return this.getGoodieBagValidDateString(startDate, endDate, 'rewards')
    }
  },
  mounted () {
  },
  methods: {
    toggleTncDialog () {
      this.$emit('showTnc', this.item.id)
    },
    creditName (campaignSlot) {
      if (campaignSlot.name) {
        return campaignSlot.name
      }
      return this.$t('goodiebagCashVouchers')
    },
    quotaName (campaignSlot) {
      if (campaignSlot.name) {
        return campaignSlot.name
      }
      return this.$t('goodiebagLimitedOffers')
    }
  }
}
</script>

<style lang="scss">
.effective-date-container{

  strong {
    color: #FFFFFF;
    font-weight: 700;
  }
}

.is-vue-desktop {
  .goodie-bag-info-container {
    .button-title {
      strong {
        color: #000000;
        font-size: 30px;
        line-height: 41px;
      }
    }
  }
}

.goodie-bag-info-container {
  .button-title {
    strong {
      color: #000000;
      font-size: 26px;
      line-height: 35px;
    }
    i {
      font-style: normal;
      color: gray;
    }
  }
}
</style>

<style lang="scss" scoped>

.is-vue-desktop {
  .goodie-bag-detail  {

    .goodie-bag-tag {
      padding: 6px;
      border-radius: 4px;
      top: 70px;
      left: 20px;
      font-size: 14px;
      line-height: 18px;
    }

    .gift-header {
      display: none;
      &.desktop {
        display: block;
      }
    }

    .expired-overlay{
      top: 52px;
    }

    .goodie-bag-container {
      display: flex;
      flex-direction: row;
      border-top: solid 13px #FF0000;

      .goodie-bag-banner-container{
        width: unset;
        width: 50%;
        padding-bottom: 37.5%;
        background: rgb(226, 0, 44);

        .goodie-bag-title-container {
          display: none;
        }
        .goodie-bag-banner{
          height: calc(100% - 38px);
          max-height: 366px;
        }
      }

      .goodie-bag-info-container {
        flex: 1;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;

        .tnc-container {
          font-size: 15px;
          line-height: 23px;
        }

        .effective-date-container {
          font-weight: 400;
          font-size: 18px;
          line-height: 25px;
          min-height: 25px;
          margin-bottom: 11px;
          margin-top: 12px;
        }

        .gift-icon {
            width: 90px;
            height: 60px;
            margin-right: 6px;
        }

        .cash-voucher-icon {
          width: 87px;
          height: 87px;
          margin-right: 6px;
        }

        .find-container{
          .label{
            font-size: 18px;
          }
        }

        .button-container {
          padding-top: 10px;
          padding-bottom: 8px;

          .button-detail {
            .button-title {
              font-size: 18px;
              line-height: 25px;
              font-weight: 600;
            }
            .button-description {
              font-weight: 600;
              font-size: 18px;
              line-height: 25px;
              display: flex;
              align-items: center;
              padding-right: 70px;
              .description{
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
              }
            }
          }
        }

        .goodie-bag-title-container {
          display: block;
          width: 100%;

          .goodie-bag-title{
            font-size: 30px;
            line-height: 41px;

          }

        }

      }
    }

  }

}

.goodie-bag-detail{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  position: relative;

  .gift-header {
    display: block;
    margin-bottom: 2px;
    &.desktop {
      display: none;
    }
  }

  .gift-header.expired{
    filter: opacity(0.35);
  }

  .goodie-bag-title-container {
    .goodie-bag-title{
      font-weight: bold;
      font-size: 22px;
      line-height: 30px;
      color: #FFFFFF;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .find-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    .label{
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      text-decoration-line: underline;
      color: #FFFFFF;
      margin-left: 4px;
      margin-bottom: 12px;
      margin-top: 5px;
    }
    img{
      margin-bottom: 3px;
      margin-left: 6px;
    }
  }

  .expired-overlay{
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.6);
  }

  .goodie-bag-tag {
    position: absolute;
    padding: 4px;
    background: #E2002C;
    border-radius: 4px;
    top: 60px;
    left: 12px;
    font-weight: 800;
    font-size: 10px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  .goodie-bag-container{
    margin-top: -1px;
    border-top: solid 7px #FF0000;
    width: 100%;

    .goodie-bag-info-container{
      padding-top: 10px;
      padding-bottom: 16px;
      padding-left: 16px;
      padding-right: 16px;
      display: flex;
      flex-direction: column;

      .tnc-container{
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        flex: 1;
        width: 100%;
      }

      .goodie-bag-title-container {
        display: none;
      }

      .tnc-container{
        cursor: pointer;
        font-size: 12px;
        line-height: 16px;
        text-decoration-line: underline;
        color: #FFFFFF;
        margin-top: 4px;
        margin-left: 4px;
      }

      .effective-date-container{
        font-size: 14px;
        line-height: 18px;
        color: #FFFFFF;
        margin-bottom: 14px;

      }

      .button-container{
        margin-bottom: 12px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        background: #FFFFFF;
        border-radius: 10px;
        padding-left: 16px;
        padding-top: 8px;
        padding-bottom: 7px;
        position: relative;

        &.gift {
          padding-right: 0;
        }

        .gift-icon {
            width: 90px;
            flex: none;
        }

        .cash-voucher-icon {
            width: 87px;
            height: 87px;
            flex: none;
          }

        .button-detail{
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .button-description {
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
            color: #E2002C;
            margin-bottom: 1px;
            display: flex;
            align-items: center;
            padding-right: 14px;
            .description{
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
            .red-arrow-right {
              margin-left: 7px;
              margin-bottom: 0;
            }
          }
          .button-title {
            font-size: 16px;
            line-height: 22px;
            color: #000000;
          }

        }
      }
    }

    .goodie-bag-banner-container{
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;

      .goodie-bag-banner {
        object-fit: cover;
        position: absolute;
        width: 100%;
        height: 100%;
      }

      .goodie-bag-title-container {
        height: 119px;
        position: absolute;
        bottom:0;
        left:0;
        right:0;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-content: flex-start;
        background: linear-gradient(354.08deg, rgba(45, 45, 45, 0.65) 16.5%, rgba(61, 61, 61, 0) 85.17%);
      }

    }
  }
}
</style>
