<template>
  <div>
    <not-find />
  </div>
</template>
<script>
import NotFind from '@/components/NotFind'
export default {
  name: 'PageNotFind',
  components: {
    NotFind
  }
}
</script>
